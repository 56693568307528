import React from "react";
import { ChevronRight } from "@mui/icons-material";
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export const Breadcrumb = ({ menu }) => {

    const location = useLocation();
    let pathnames = location.pathname.split("/").filter((x) => x)

    if (pathnames.includes('edit')) {
        pathnames = pathnames.slice(0, pathnames.indexOf('edit') + 1)
    }

    return (
        <BootstrapBreadcrumb>

            <BootstrapBreadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard/home', className: "text-decoration-none" }}>
                Dashboard
            </BootstrapBreadcrumb.Item>

            {pathnames.filter(item => item !== 'dashboard').map((value, index) => {

                const pathVerification = pathnames.slice(0, index + 2).join('/')
                const isLast = index + 1 === pathnames.length - 1;
                let menuItem = menu.find(el => el.path === `/${pathVerification}`)

                if (!menuItem) {

                    let childList = menu.map(parent => {
                        return parent.children.map(child => {
                            return { ...child, path: parent.path.concat(child.path) }
                        }).flat()
                    }).flat()

                    menuItem = childList.find(el => el.path === `/${pathVerification}`)
                }

                const to = menuItem ? menuItem.path : ''
                const key = `${index}-${to}`

                if (!menuItem) {
                    return <span key={key}></span>
                }

                if (isLast) {
                    return (
                        <BootstrapBreadcrumb.Item key={key} active>
                            <ChevronRight color="disabled" />
                            {menuItem ? menuItem.label : value}
                        </BootstrapBreadcrumb.Item>
                    )
                }

                return (
                    <BootstrapBreadcrumb.Item key={key} linkAs={Link} linkProps={{ to, className: "text-decoration-none" }}>
                        <ChevronRight color="disabled" />
                        {menuItem ? menuItem.label : value}
                    </BootstrapBreadcrumb.Item>
                )
            })}

        </BootstrapBreadcrumb>
    );
};
