import './TelegramConnection.css'
import { Telegram } from '@mui/icons-material'
import { Card } from "react-bootstrap"
import { useAuth } from '../../../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export const TelegramConnection = () => {

    const { user } = useAuth()
    const navigate = useNavigate()

    if (user.chatId) {
        return <></>
    }

    return (
        <Card className="TelegramConnection mb-3" onClick={_ => navigate('/dashboard/account/telegram')}>
            <div className='icon'>
                <Telegram />
            </div>

            <div>
                <h5>Aguardando conexão com Telegram</h5>
                <p>Conecte-se com sua conta do Telegram para receber os alertas</p>
                <span>Continuar</span>
            </div>
        </Card>
    )
}