import './DashboardHeader.css'

import { MenuRounded } from "@mui/icons-material"
import { useAuth } from "../contexts/AuthContext"
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export const DashboardHeader = ({ toggle }) => {

    const { user } = useAuth()

    return (
        <header className="bg-white px-3 py-2 shadow-sm">
            <div className="d-flex flex-fill align-items-center gap-2">
                <button onClick={toggle} className="btn btn-light bg-white">
                    <MenuRounded />
                </button>
                <div className="flex-fill">
                    <h1 className="m-0 h6 d-inline text-gradient"><strong>Roulette Notify</strong></h1>
                </div>

                <Dropdown drop='down'>
                    <Dropdown.Toggle className='btn-account' variant='light'>
                        <img src={user.photoURL} alt={user.name} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/dashboard/account">Minha Conta</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/logout">Sair</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </header>
    )
}