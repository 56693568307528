import './Sidebar.css'
import { useAuth } from "../contexts/AuthContext"
import { CloseRounded } from '@mui/icons-material';
import { PlanVersionButton } from './PlanVersionButton';
import { useDashboard } from '../contexts/DashboardContext';

export const PlanButton = ({ plans, userPlan, onClick }) => {
    
    if (!plans || plans.length === 0) return <></>

    let plan = plans.find(plan => plan.type === userPlan.type)

    if (!plan) return <></>

    return <PlanVersionButton type={plan.type} onClick={onClick}>{plan.name}</PlanVersionButton>
}

export const MenuListItem = ({ selected, onClick, children }) => {
    return <li onClick={onClick} className={selected ? `selected` : ''}>{children}</li>
}

export const Sidebar = ({ menu, open, toggle, onClickMenu, onClickPlan, menuSelected }) => {

    const { user } = useAuth()
    const dashboard = useDashboard()

    if (!open) {
        return <></>
    }

    return (
        <aside className="sidebar">

            <div className='close'>
                <button onClick={toggle}>
                    <CloseRounded />
                </button>
            </div>

            <div className="header">
                <img src={user.photoURL} alt={user.name} />
                <div>
                    <h6>{user.name}</h6>
                    <span>{user.email}</span>
                </div>
                <PlanButton
                    plans={dashboard.plans.data}
                    userPlan={user.plan}
                    onClick={onClickPlan} />
            </div>
            <nav className='menu'>
                <ul>
                    {menu.map(item => {
                        return (
                            <MenuListItem key={item.path} onClick={_ => onClickMenu(item.path)} selected={menuSelected === item.path}>
                                {item.icon} {item.label}
                            </MenuListItem>
                        )
                    })}
                </ul>
            </nav>
        </aside>
    )
}