import { Button } from "react-bootstrap"

export const EmptyData = ({ title, subtitle, button, buttonClick }) => {

    let shouldShowButton = button && buttonClick

    return (  
        <div className="text-center px-2">
            <h6 className="m-0 mb-2"><strong>{title}</strong></h6>
            <p className="m-0">{subtitle}</p>
            {shouldShowButton && <Button className="mt-3" onClick={_ => buttonClick()} variant="dark">{button}</Button>}
        </div>
    )
}