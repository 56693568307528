import { Button } from "react-bootstrap"

export const Header = ({ clickCreateAccount }) => {
    return (
        <header className="bg-white w-100 px-3 py-2 shadow-sm d-flex align-items-center justify-content-between gap-3">
            <img src="/images/logo45.png" alt="Roulette Notify Logo" />
            <div className="flex-fill">
                <h1 className="m-0 h6 text-dark">Roulette Notify</h1>
            </div>
            <Button className="d-none d-lg-block" onClick={clickCreateAccount}>Entre ou Cadastre-se</Button>
        </header>
    )
} 