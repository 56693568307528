import { Button, Container } from "react-bootstrap"

export const Hero = ({ clickCreateAccount }) => {
    return (
        <div style={{ paddingBlock: 96 }}>
            <Container className="d-flex flex-column flex-lg-row align-items-center">
                <div className="text-center" style={{ maxWidth: 550 }}>
                    <h2 className="mb-3">Domine a Roleta com Padrões Personalizados e Alertas Rápidos</h2>
                    <p className="mb-3">Configure padrões únicos e receba notificações instantâneas no Telegram. Ganhe controle total e maximize suas estratégias.</p>
                    <Button onClick={clickCreateAccount}>Crie sua conta Grátis</Button>
                </div>
                <img className="w-100" src="/images/home-hero.png" alt="Phone" />
            </Container>
        </div>
    )
}