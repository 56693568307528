import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {

  const [toasts, setToasts] = useState([])

  const addToast = useCallback((message, variant) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, variant },
    ])
  }, [])

  const removeToast = useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, [])

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastContainer position="bottom-center" className="p-3">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            bg={toast.variant}
            onClose={() => removeToast(toast.id)}
            delay={3000}
            autohide
          >
            <Toast.Body className={'text-white text-center'}>{toast.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

// Custom Hook
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast deve ser usado dentro de um ToastProvider');
  }

  const { addToast } = context;

  const success = (message) => addToast(message, 'success');
  const error = (message) => addToast(message, 'danger');

  return { success, error };
};
