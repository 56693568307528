import './RaceTrack.css'
import { Add, Remove, UndoOutlined } from '@mui/icons-material';
import { useEffect } from 'react'
import { Alert, ProgressBar } from 'react-bootstrap';

const racetrackNumbers = [
    0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6,
    27, 13, 36, 11, 30, 8, 23, 10, 5, 24,
    16, 33, 1, 20, 14, 31, 9, 22, 18, 29,
    7, 28, 12, 35, 3, 26
];

function getNumberWithNeighbors(number, neighbors) {
    const index = racetrackNumbers.indexOf(number);

    if (index === -1) {
        throw new Error("Número não encontrado na roleta.");
    }

    const result = [];
    const totalNumbers = racetrackNumbers.length;

    for (let i = -neighbors; i <= neighbors; i++) {
        const neighborIndex = (index + i + totalNumbers) % totalNumbers;
        result.push(racetrackNumbers[neighborIndex]);
    }

    return result;
}

export const RaceTrack = ({
    numbers = [],
    probilityPercent,
    maxProbabilityPercent,
    isMaxValid,
    onAdd,
    onRollback,
    raceNeighbors = 1,
    onChangeRaceTrackNeighbors = {}
}) => {

    function plusNeighbors() {
        let newNumber = raceNeighbors + 1
        if (newNumber <= 9) {
            onChangeRaceTrackNeighbors(newNumber)
        }
    }

    function minusNeighbors() {
        let newNumber = raceNeighbors - 1
        if (newNumber >= 0) {
            return onChangeRaceTrackNeighbors(newNumber)
        }
    }

    useEffect(_ => {

        let elements = document.querySelectorAll('.red_num_shape, .black_num_shape, .green_shape')
        elements.forEach(el => {

            let inFocus = el.getAttribute('data-bet-spot-id')
            let focusNumber = parseInt(inFocus)

            if (numbers.flat().includes(focusNumber)) {
                el.classList.add('selected')
            } else {
                el.classList.remove('selected')
            }

            el.onmouseenter = _ => {
                let numbersWithNeighbors = getNumberWithNeighbors(focusNumber, raceNeighbors)
                el.onclick = _ => onAdd(numbersWithNeighbors)

                numbersWithNeighbors.forEach(number => {
                    let mEl = document.querySelector(`[data-bet-spot-id="${number}"]`)
                    if (mEl) mEl.classList.add('highlighted')
                })
            }

            el.onmouseleave = _ => {
                elements.forEach(el => {
                    el.classList.remove('highlighted')
                    el.onclick = null
                })
            }
        })
    }, [raceNeighbors, numbers, onAdd])

    return (
        <div className='racetrack-container'>

            <div className="racetrack">
                <svg viewBox="0 0 287.578125 74.203125" textRendering="geometricPrecision">
                    <defs></defs>
                    <g className="racetrack-wrapper">
                        <path className="green_shape" data-bet-spot-id="0" d="M 270.111 67.338 A 36.052 36.052 0 0 0 284.564 48.839 L 266.858 42.743 A 17.326 17.326 0 0 1 259.913 51.633 Z"></path>
                        <rect className="red_num_shape selected" data-bet-spot-id="1" x="72.497" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="2" x="167.549" y="54.427" width="15.842" height="18.726"></rect>
                        <path className="red_num_shape" data-bet-spot-id="3" d="M 270.111 6.866 A 36.052 36.052 0 0 1 284.564 25.365 L 266.858 31.461 A 17.326 17.326 0 0 0 259.913 22.571 Z"></path>
                        <rect className="black_num_shape" data-bet-spot-id="4" x="199.233" y="54.427" width="15.842" height="18.726"></rect>
                        <path className="red_num_shape" data-bet-spot-id="5" d="M 21.298 4.699 A 36.052 36.052 0 0 0 6.528 17.997 L 22.409 27.921 A 17.326 17.326 0 0 1 29.507 21.529 Z"></path>
                        <rect className="black_num_shape" data-bet-spot-id="6" x="104.181" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="7" x="199.233" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <path className="black_num_shape" data-bet-spot-id="8" d="M 21.298 69.505 A 36.052 36.052 0 0 1 6.528 56.207 L 22.409 46.283 A 17.326 17.326 0 0 0 29.507 52.675 Z"></path>
                        <rect className="red_num_shape" data-bet-spot-id="9" x="135.865" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <path className="black_num_shape" data-bet-spot-id="10" d="M 1.05 37.102 A 36.052 36.052 0 0 1 6.528 17.997 L 22.409 27.921 A 17.326 17.326 0 0 0 19.776 37.102 Z"></path>
                        <rect className="black_num_shape" data-bet-spot-id="11" x="40.812999999999995" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="12" x="230.917" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="13" x="72.497" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="14" x="104.181" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="15" x="230.917" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="16" x="40.812999999999995" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="17" x="135.865" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="18" x="167.549" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="19" x="215.07500000000002" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="20" x="88.339" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="21" x="183.39100000000002" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="22" x="151.70700000000002" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <path className="red_num_shape" data-bet-spot-id="23" d="M 6.528 56.207 A 36.052 36.052 0 0 1 1.05 37.102 L 19.776 37.102 A 17.326 17.326 0 0 0 22.409 46.283 Z"></path>
                        <path className="black_num_shape" data-bet-spot-id="24" d="M 37.102 19.776 L 40.812999999999995 19.776 L 40.812999999999995 1.0499999999999998 L 37.102 1.0499999999999998 L 37.102 1.05 A 36.052 36.052 0 0 0 21.298 4.699 L 29.507 21.529 A 17.326 17.326 0 0 1 37.102 19.776 Z"></path>
                        <rect className="red_num_shape" data-bet-spot-id="25" x="151.70700000000002" y="54.427" width="15.842" height="18.726"></rect>
                        <path className="black_num_shape" data-bet-spot-id="26" d="M 284.564 25.365 A 36.052 36.052 0 0 1 284.564 48.839 L 266.858 42.743 A 17.326 17.326 0 0 0 266.858 31.461 Z"></path>
                        <rect className="red_num_shape" data-bet-spot-id="27" x="88.339" y="54.427" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="28" x="215.07500000000002" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="black_num_shape" data-bet-spot-id="29" x="183.39100000000002" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <path className="red_num_shape" data-bet-spot-id="30" d="M 37.102 54.427 L 40.812999999999995 54.427 L 40.812999999999995 73.153125 L 37.102 73.153125 L 37.102 73.154 A 36.052 36.052 0 0 1 21.298 69.505 L 29.507 52.675 A 17.326 17.326 0 0 0 37.102 54.428 Z"></path>
                        <rect className="black_num_shape" data-bet-spot-id="31" x="120.023" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <path className="red_num_shape" data-bet-spot-id="32" d="M 250.476 54.427 L 246.76500000000001 54.427 L 246.76500000000001 73.153125 L 250.476 73.153125 L 250.476 73.154 A 36.052 36.052 0 0 0 270.111 67.338 L 259.913 51.633 A 17.326 17.326 0 0 1 250.476 54.428 Z"></path>
                        <rect className="black_num_shape" data-bet-spot-id="33" x="56.654999999999994" y="1.0499999999999998" width="15.842" height="18.726"></rect>
                        <rect className="red_num_shape" data-bet-spot-id="34" x="120.023" y="54.427" width="15.842" height="18.726"></rect>
                        <path className="black_num_shape" data-bet-spot-id="35" d="M 250.476 19.776 L 246.76500000000001 19.776 L 246.76500000000001 1.0499999999999998 L 250.476 1.0499999999999998 L 250.476 1.05 A 36.052 36.052 0 0 1 270.111 6.866 L 259.913 22.571 A 17.326 17.326 0 0 0 250.476 19.776 Z"></path>
                        <rect className="red_num_shape" data-bet-spot-id="36" x="56.654999999999994" y="54.427" width="15.842" height="18.726"></rect>
                        <path className="sector_color" data-bet-spot-id="jeu_zero" d="M 227.206 19.776 L 250.47 19.776 A 17.326 17.326 0 0 1 250.47 54.427 L 227.206 54.427 A 17.326 17.326 0 0 1 227.206 19.776 Z"></path>
                        <path className="sector_color" data-bet-spot-id="orphelins_a_cheval" d="M 72.497 19.776 L 104.181 54.427 L 151.70700000000002 54.427 L 151.70700000000002 19.776 Z"></path>
                        <path className="sector_color" data-bet-spot-id="tiers_du_cylindre" d="M 104.181 54.427 L 37.101784374999994 54.427 A 17.326 17.326 0 0 1 37.101784374999994 19.776 L 72.497 19.776 Z"></path>
                        <path className="sector_color" data-bet-spot-id="voisins_du_zero" d="M 151.70700000000002 19.776 L 227.206 19.776 A 17.326 17.326 0 0 0 227.206 54.427 L 151.70700000000002 54.427 Z"></path>
                        <path d="M 37.102 1.0499999999999998 L 250.476 1.0499999999999998 A 36.052 36.052 0 0 1 250.476 73.15299999999999 L 37.102 73.15299999999999 A 36.052 36.052 0 0 1 37.102 1.0499999999999998 M 37.102 19.776 L 250.476 19.776 A 17.326 17.326 0 0 1 250.476 54.427 L 37.102 54.427 A 17.326 17.326 0 0 1 37.102 19.776 M 40.812999999999995 1.0499999999999998 v 18.726 M 56.655 1.0499999999999998 v 18.726 M 72.497 1.0499999999999998 v 18.726 M 88.339 1.0499999999999998 v 18.726 M 104.181 1.0499999999999998 v 18.726 M 120.023 1.0499999999999998 v 18.726 M 135.865 1.0499999999999998 v 18.726 M 151.707 1.0499999999999998 v 18.726 M 167.549 1.0499999999999998 v 18.726 M 183.391 1.0499999999999998 v 18.726 M 199.233 1.0499999999999998 v 18.726 M 215.075 1.0499999999999998 v 18.726 M 230.917 1.0499999999999998 v 18.726 M 246.759 1.0499999999999998 v 18.726 M 40.812999999999995 54.427 v 18.726 M 56.655 54.427 v 18.726 M 72.497 54.427 v 18.726 M 88.339 54.427 v 18.726 M 104.181 54.427 v 18.726 M 120.023 54.427 v 18.726 M 135.865 54.427 v 18.726 M 151.707 54.427 v 18.726 M 167.549 54.427 v 18.726 M 183.391 54.427 v 18.726 M 199.233 54.427 v 18.726 M 215.075 54.427 v 18.726 M 230.917 54.427 v 18.726 M 246.759 54.427 v 18.726 M 21.298 69.505 L 29.507 52.675 M 6.528 56.207 L 22.409 46.283 M 1.05 37.102 L 19.776 37.102 M 6.528 17.997 L 22.409 27.921 M 6.528 17.997 L 22.409 27.921 M 21.298 4.699 L 29.507 21.529 M 270.111 6.866 L 259.913 22.571 M 284.564 25.365 L 266.858 31.461 M 284.564 48.839 L 266.858 42.743 M 284.564 48.839 L 266.858 42.743 M 270.111 67.338 L 259.913 51.633 M 72.497 19.776 L 104.181 54.427 M 151.70700000000002 19.776 L 151.70700000000002 54.427 M 227.206 19.776 a 11.918 12.176 0 0 0 0 34.651" className="border"></path>
                        <text className="green_shape_label vertical_center" x="94.400%" y="72.100%" dx="0em" dy="0em">0</text>
                        <text className="red_num_shape_label vertical_center" x="28.000%" y="14.000%" dx="0em" dy="0em">1</text>
                        <text className="black_num_shape_label vertical_center" x="61.000%" y="86.000%" dx="0em" dy="0em">2</text>
                        <text className="red_num_shape_label vertical_center" x="94.400%" y="27.900%" dx="0em" dy="0em">3</text>
                        <text className="black_num_shape_label vertical_center" x="72.000%" y="86.000%" dx="0em" dy="0em">4</text>
                        <text className="red_num_shape_label vertical_center" x="6.700%" y="23.300%" dx="0em" dy="0em">5</text>
                        <text className="black_num_shape_label vertical_center" x="39.000%" y="86.000%" dx="0em" dy="0em">6</text>
                        <text className="red_num_shape_label vertical_center" x="72.000%" y="14.000%" dx="0em" dy="0em">7</text>
                        <text className="black_num_shape_label vertical_center" x="6.700%" y="76.700%" dx="0em" dy="0em">8</text>
                        <text className="red_num_shape_label vertical_center" x="50.000%" y="14.000%" dx="0em" dy="0em">9</text>
                        <text className="black_num_shape_label vertical_center" x="4.000%" y="40.100%" dx="0em" dy="0em">10</text>
                        <text className="black_num_shape_label vertical_center" x="16.900%" y="86.000%" dx="0em" dy="0em">11</text>
                        <text className="red_num_shape_label vertical_center" x="83.100%" y="14.000%" dx="0em" dy="0em">12</text>
                        <text className="black_num_shape_label vertical_center" x="28.000%" y="86.000%" dx="0em" dy="0em">13</text>
                        <text className="red_num_shape_label vertical_center" x="39.000%" y="14.000%" dx="0em" dy="0em">14</text>
                        <text className="black_num_shape_label vertical_center" x="83.100%" y="86.000%" dx="0em" dy="0em">15</text>
                        <text className="red_num_shape_label vertical_center" x="16.900%" y="14.000%" dx="0em" dy="0em">16</text>
                        <text className="black_num_shape_label vertical_center" x="50.000%" y="86.000%" dx="0em" dy="0em">17</text>
                        <text className="red_num_shape_label vertical_center" x="61.000%" y="14.000%" dx="0em" dy="0em">18</text>
                        <text className="red_num_shape_label vertical_center" x="77.500%" y="86.000%" dx="0em" dy="0em">19</text>
                        <text className="black_num_shape_label vertical_center" x="33.500%" y="14.000%" dx="0em" dy="0em">20</text>
                        <text className="red_num_shape_label vertical_center" x="66.500%" y="86.000%" dx="0em" dy="0em">21</text>
                        <text className="black_num_shape_label vertical_center" x="55.500%" y="14.000%" dx="0em" dy="0em">22</text>
                        <text className="red_num_shape_label vertical_center" x="4.000%" y="59.900%" dx="0em" dy="0em">23</text>
                        <text className="black_num_shape_label vertical_center" x="11.500%" y="15.000%" dx="0em" dy="0em">24</text>
                        <text className="red_num_shape_label vertical_center" x="55.500%" y="86.000%" dx="0em" dy="0em">25</text>
                        <text className="black_num_shape_label vertical_center" x="96.400%" y="50.000%" dx="0em" dy="0em">26</text>
                        <text className="red_num_shape_label vertical_center" x="33.500%" y="86.000%" dx="0em" dy="0em">27</text>
                        <text className="black_num_shape_label vertical_center" x="77.500%" y="14.000%" dx="0em" dy="0em">28</text>
                        <text className="black_num_shape_label vertical_center" x="66.500%" y="14.000%" dx="0em" dy="0em">29</text>
                        <text className="red_num_shape_label vertical_center" x="11.500%" y="85.000%" dx="0em" dy="0em">30</text>
                        <text className="black_num_shape_label vertical_center" x="44.500%" y="14.000%" dx="0em" dy="0em">31</text>
                        <text className="red_num_shape_label vertical_center" x="89.100%" y="84.500%" dx="0em" dy="0em">32</text>
                        <text className="black_num_shape_label vertical_center" x="22.500%" y="14.000%" dx="0em" dy="0em">33</text>
                        <text className="red_num_shape_label vertical_center" x="44.500%" y="86.000%" dx="0em" dy="0em">34</text>
                        <text className="black_num_shape_label vertical_center" x="89.100%" y="15.500%" dx="0em" dy="0em">35</text>
                        <text className="red_num_shape_label vertical_center" x="22.500%" y="86.000%" dx="0em" dy="0em">36</text>
                        <text className="sector_color_label vertical_center" x="83.700%" y="50.000%" dx="0em" dy="0em">ZERO</text>
                        <text className="sector_color_label vertical_center" x="42.400%" y="50.000%" dx="0em" dy="0em">ORPHELINS</text>
                        <text className="sector_color_label vertical_center" x="19.100%" y="50.000%" dx="0em" dy="0em">TIER</text>
                        <text className="sector_color_label vertical_center" x="63.500%" y="50.000%" dx="0em" dy="0em">VOISINS</text>
                    </g>
                </svg>
            </div>

            <div className='d-flex align-items-center gap-4 mb-3'>
                <button onClick={_ => onRollback()} type='button' className='btn-option' >
                    <UndoOutlined />
                </button>

                <div className='race-options'>
                    <button onClick={minusNeighbors} type='button' className='btn-option'><Remove /></button>
                    <span>{raceNeighbors}</span>
                    <button type='button' onClick={plusNeighbors} className='btn-option'><Add /></button>
                </div>
            </div>

            <div style={{ width: '68%', margin: '4px auto' }}>
                <ProgressBar variant={isMaxValid ? 'success' : 'danger'} now={probilityPercent} label={`${probilityPercent}%`} />
                {!isMaxValid && <ProbabilityErrorAlert maxProbabilityPercent={maxProbabilityPercent} />}
            </div>
        </div>
    )
}

export const ProbabilityErrorAlert = ({ maxProbabilityPercent }) => {
    return (
        <Alert variant='danger' className='text-center small p-2 mt-2'>
            A quantidade de números selecionados não pode ultrapassar {maxProbabilityPercent}% de probabilidade
        </Alert>
    )
}