import { Container } from "react-bootstrap"

export const Footer = () => {
    return (
        <footer className="bg-dark text-light">
            <Container className="py-5 text-center">
                <p className="p-0 m-0">@2024 Roulette Notify. Todos os direitos reservados.</p>
            </Container>
        </footer>
    )
}