import { Card, Table } from "react-bootstrap"
import { useDashboard } from "../../../../contexts/DashboardContext"
import { EmptyData } from "../../../../components/EmptyData"
import { format, isToday, isYesterday } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const AlertsTable = ({ alerts }) => {

    if (!alerts || alerts.length <= 0) {
        return <></>
    }

    const formatDate = (timestamp) => {
        const date = timestamp.toDate()

        if (isToday(date)) {
            return `Hoje às ${format(date, "HH:mm")}`
        }

        if (isYesterday(date)) {
            return `Ontem às ${format(date, "HH:mm")}`
        }

        return format(date, "dd/MM/yyyy 'às' HH:mm", { locale: ptBR });
    }

    return (
        <Table responsive>
            <thead>
                <tr>
                    <th>Padrão</th>
                    <th>Roleta</th>
                    <th>Histórico</th>
                    <th>Data</th>
                </tr>
            </thead>
            <tbody>
                {alerts.map(alert => (
                    <tr key={alert.id}>
                        <td>{alert.pattern}</td>
                        <td>{alert.roulette}</td>
                        <td>{alert.history}</td>
                        <td>{formatDate(alert.datetime)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export const AlertsSection = ({ onClickCreatePattern }) => {

    const dashboard = useDashboard()

    const hasPatterns = dashboard.patterns.length > 0
    const hasAlerts = dashboard.recentAlerts.length > 0

    return (
        <Card className="mb-4">
            <h5 className="mb-5">Histórico de alertas</h5>

            <AlertsTable alerts={dashboard.recentAlerts} />

            {(hasPatterns && !hasAlerts) && (
                <EmptyData
                    title="Nenhum alerta enviado"
                    subtitle="Você receberá um alerta sempre que  os padrões criados forem detectados" />
            )}

            {(!hasPatterns && !hasAlerts) && (
                <EmptyData
                    title="Nenhum alerta enviado"
                    subtitle="Crie seu primeiro padrão para começar a receber alertas"
                    button="Criar meu primeiro padrão"
                    buttonClick={onClickCreatePattern} />
            )}
        </Card>
    )
}