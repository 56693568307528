import { collection, deleteDoc, doc, getDoc, getDocs, limit, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore'
import { auth, firestore } from "../firebase"
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.request.use(async config => {
    const token = await auth.currentUser.getIdToken()
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
}, error => {
    return Promise.reject(error);
})

export const loginGoogle = async () => new Promise(async (resolve, reject) => {
    const whiteLoginErrors = [
        'auth/popup-closed-by-user',
        'auth/cancelled-popup-request'
    ]

    try {
        const provider = new GoogleAuthProvider()
        let userCredential = await signInWithPopup(auth, provider)
        let firebaseUser = userCredential.user
        await ensureUserDocument(firebaseUser)
        resolve({ type: 'success' })
    } catch (error) {
        if (whiteLoginErrors.includes(error.code)) {
            resolve({ type: 'cancelled' })
        } else {
            reject(error)
        }
    }
})

export const fetchPlans = async () => {
    const querySnapshot = await getDocs(collection(firestore, "/plans"))
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

export const fetchPatterns = async (userId) => {
    const querySnapshot = await getDocs(collection(firestore, `users/${userId}/patterns`))
    const list = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    list.sort((a, b) => (a.active === b.active) ? 0 : a.active ? -1 : 1)
    return list
}

export const fetchRoulettes = async () => {
    const querySnapshot = await getDocs(collection(firestore, "/roulettes"))
    const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    items.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
    return items
}

export const savePattern = async (userId, pattern) => {
    const patternsRef = collection(firestore, `users/${userId}/patterns`)
    const newPatternRef = pattern.id ? doc(patternsRef, pattern.id) : doc(patternsRef)
    return await setDoc(newPatternRef, { ...pattern, id: newPatternRef.id });
}

export const deletePattern = async (userId, patternId) => {
    const docRef = doc(firestore, `users/${userId}/patterns`, patternId)
    return await deleteDoc(docRef);
}

export const saveTelegramCode = async (userId, telegramCode) => {
    const data = { chatId: telegramCode }
    const docRef = doc(firestore, "users/", userId);
    return await updateDoc(docRef, data).then(_ => data)
}

export const createPreference = async (planId) => {
    return axios.post('preferences/create', ({ planId }))
}

export const createSubscription = async (planId, cardToken, payer) => {
    return axios.post('subscriptions/create', ({ planId, cardToken, payer }))
}

export const createUserPlan = async (planId) => {
    return axios.post('subscriptions/create/plan', ({ planId }))
}

export const fetchSubscription = async (preApprovalId) => {
    return axios.get(`subscriptions/${preApprovalId}`)
}

/**
 * Create (if needed) and retrieve the user document
 */
export const ensureUserDocument = async (firebaseUser) => {

    const userId = firebaseUser.uid
    const userRef = doc(firestore, 'users', userId)
    const userDoc = await getDoc(userRef)

    if (!userDoc.exists()) {
        await setDoc(userRef, {
            uid: userId,
            name: firebaseUser.displayName,
            email: firebaseUser.email,
            photoURL: firebaseUser.photoURL,
            phoneNumber: firebaseUser.phoneNumber,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            status: 'ACTIVE',
            patterns: [],
            plan: {
                type: "FREE",
                activationDate: serverTimestamp(),
                expirationDate: null,
                paidAmount: 0.0
            }
        })
    }

    return userDoc.data()
}

const observeAlerts = (userUid, callback) => {
    const q = query(collection(firestore, "alerts"), where("userUid", "==", userUid), limit(30))
    return onSnapshot(q, (querySnapshot) => {
        const alerts = querySnapshot.docs.map(doc => doc.data())
        callback(alerts)
    })
}

const observePatterns = (userUid, callback) => {
    const collectionRef = collection(firestore, `users/${userUid}/patterns`)
    return onSnapshot(collectionRef, (querySnapshot) => {
        const patterns = querySnapshot.docs.map(doc => doc.data())
        callback(patterns)
    })
}

const observeUser = (userUid, callback) => {
    const userRef = doc(firestore, 'users', userUid)
    return onSnapshot(userRef, (querySnapshot) => callback(querySnapshot.data()))
}

export const Api = {
    fetchPlans,
    fetchPatterns,
    fetchRoulettes,
    fetchSubscription,
    savePattern,
    deletePattern,
    observeAlerts,
    observePatterns,
    observeUser,
    createUserPlan
}