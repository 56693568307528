import { Spinner } from "react-bootstrap"

export const LoaderButton = ({
    onClick,
    children,
    className = '',
    visible = true,
    loading = false,
    disabled = false
}) => {

    if (!visible) {
        return <></>
    }

    return (
        <button className={className} disabled={disabled || loading} onClick={onClick}>
            {loading && <Spinner size="sm" />}
            {!loading && children}
        </button>
    )
}