import './RuleField.css'

import { ArrowForwardRounded, DeleteOutlineOutlined } from "@mui/icons-material"
import { Form } from "react-bootstrap"

export const RuleField = ({
    rule,
    onChangeFrom,
    onClickTrackPadTo,
    onClickDeleteRule,
    disableDelete = false
}) => (
    <div className='rule-field'>

        <Form.Group className='flex-fill'>
            <Form.Control
                onChange={e => onChangeFrom(e.target.value)}
                value={rule.from.value}
                type="number"
                placeholder="Gatilho"
                isInvalid={rule.from.error}
                maxLength={2} />

            <Form.Control.Feedback>{rule.from.error}</Form.Control.Feedback>
        </Form.Group>

        <div className='d-flex align-items-center gap-2 px-2'>
            <span className='d-none d-lg-block small'>Para</span>
            <ArrowForwardRounded />
        </div>

        <Form.Group className='flex-fill'>
            <Form.Control
                readOnly
                value={rule.to.value}
                onClick={onClickTrackPadTo}
                placeholder="Alvos"
                isInvalid={rule.to.error} />
        </Form.Group>

        <button type='button' className='btn-delete-rule' onClick={onClickDeleteRule} disabled={disableDelete}>
            <DeleteOutlineOutlined />
        </button>

    </div>
)