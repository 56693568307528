import { Container } from "react-bootstrap"

export const Settings = () => {
    return (
        <div className="scrollable">
            <Container className="py-3">
                Settings
            </Container>
        </div>
    )
}