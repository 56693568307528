import { Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AlertsSection } from "./components/AlertsSection"
import { PatternsSection } from "./components/PatternsSection"
import { TelegramConnection } from "./components/TelegramConnect"
import { RoulettesSection } from "./components/RoulettesSection"

export const DashboardHome = () => {

    const navigate = useNavigate()

    function goCreatePattern() {
        navigate('/dashboard/patterns/new')
    }

    return (
        <div className="scrollable">
            <Container className="py-3">
                
                <TelegramConnection />
                <PatternsSection onClickCreatePattern={goCreatePattern} /> 
                <AlertsSection onClickCreatePattern={goCreatePattern} />
                <RoulettesSection />

            </Container>
        </div>
    )
}