import { Button, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export const NotFound = () => {

    const navigate = useNavigate()

    return (
        <Container className="vh-100 d-flex align-items-center justify-content-center flex-column">
            <h2 className="h4">Página não encontrada</h2>
            <p>A página que você tentou acessar não existe</p>
            <Button onClick={_ => navigate('/')}>Voltar para Início</Button>
        </Container>
    )
}