import { Container } from "react-bootstrap"

export const Faq = () => {
    return (
        <section className="py-5">
            <Container>
                <h4 className="mb-5">Perguntas Frequentes</h4>
                <h6 className="fw-bold">Como configuro um padrão?</h6>
                <p className="mb-4">É fácil! Basta acessar a seção de configuração, definir seus critérios e salvar.</p>
                <h6 className="fw-bold">Como funciona a integração com o Telegram?</h6>
                <p>Conecte sua conta do Telegram no painel de configurações para receber notificações.</p>
            </Container>
        </section>
    )
}