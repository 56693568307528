import './PatternsSection.css'
import { Card } from 'react-bootstrap'
import { EmptyData } from '../../../../components/EmptyData'
import { useDashboard } from '../../../../contexts/DashboardContext'
import { StatusDot } from '../../../../components/StatusDot'

const PatternView = ({ pattern }) => {
    return (
        <div className='pattern-view'>
            <h6>{pattern.name}</h6>
            <StatusDot
                variant={pattern.active ? 'success' : 'danger'} 
                label={pattern.active ? 'Ativo' : 'Inativo'} />
        </div>
    )
}

export const PatternsSection = ({ onClickCreatePattern }) => {

    const dashboard = useDashboard()

    const hasPatterns = dashboard.patterns.length > 0

    return (
        <Card className="mb-4">
            <h5 className="mb-5">Visão geral dos padrões</h5>

            <div className="pattern-list">
                {dashboard.patterns.map((pattern, idx) =>
                    <PatternView key={idx} pattern={pattern} />)}
            </div>

            {(!hasPatterns) && (
                <EmptyData
                    title="Nenhum padrão criado"
                    subtitle="Crie seu primeiro padrão para começar a receber alertas"
                    button="Criar meu primeiro padrão"
                    buttonClick={onClickCreatePattern} />
            )}
        </Card>
    )
}