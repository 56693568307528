import Modal from 'react-bootstrap/Modal';
import { CloseRounded } from '@mui/icons-material';
import { Button, Card } from 'react-bootstrap';
import { RaceTrack } from '../../../components/RaceTrack';
import { useEffect, useState } from 'react';

const MAX_PROBABILITY_PERCENT = 60

export const NumberPickerDialog = ({
    ruleNumbers = [],
    show = false,
    toggle = {},
    onConfirm = _ => { },
    onCancel = _ => { }
}) => {

    const [numbers, setNumbers] = useState(ruleNumbers)
    const [confirmAllowed, setConfirmAllowed] = useState(true)
    const [probilityPercent, setProbabilityPercent] = useState(0)
    const [raceNeighbors, setRaceNeighbors] = useState(1)

    useEffect(_ => {
        let selectedNumbersSet = Array.from(new Set(numbers.flat()))
        let newProbability = ((selectedNumbersSet.length / 37) * 100).toFixed(2)
        setProbabilityPercent(newProbability)
        setConfirmAllowed(newProbability <= MAX_PROBABILITY_PERCENT)
    }, [numbers])

    function onConfirmClick() {
        onConfirm(numbers)
        setNumbers([])
    }

    function onChangeRaceTrack(newNumbers) {
        setNumbers(numbers => [...numbers, newNumbers])
    }

    function onRollbackRaceTrack() {
        setNumbers(numbers => {
            let newNumbers = [...numbers]
            newNumbers.pop()
            return newNumbers
        })
    }

    return (
        <Modal show={show} onHide={toggle} size='large' onShow={_ => setNumbers(ruleNumbers)}>
            <Card className='p-2'>
                <div className='d-flex align-items-center'>
                    <h6 className='flex-fill m-0 px-2'>Selecione os números</h6>
                    <button onClick={toggle} type='button' className='btn btn-light bg-white'>
                        <CloseRounded />
                    </button>
                </div>

                <RaceTrack
                    onChangeRaceTrackNeighbors={count => setRaceNeighbors(count)}
                    raceNeighbors={raceNeighbors}
                    probilityPercent={probilityPercent}
                    isMaxValid={confirmAllowed}
                    maxProbabilityPercent={MAX_PROBABILITY_PERCENT}
                    numbers={numbers}
                    onRollback={onRollbackRaceTrack}
                    onAdd={onChangeRaceTrack} />

                <div className='d-flex gap-2 mt-3'>
                    <Button className='flex-fill' variant='secondary' onClick={_ => onCancel()}>Cancelar</Button>
                    <Button className='flex-fill' variant='dark' onClick={_ => onConfirmClick()} disabled={!confirmAllowed}>Confirmar</Button>
                </div>
            </Card>
        </Modal>
    );
}