import React, { useContext } from "react";
import AuthProvider, { AuthContext } from "./contexts/AuthContext";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { DashboardHome } from "./pages/dashboard/home/Home";
import { Patterns } from "./pages/dashboard/patterns/Patterns";
import { Alerts } from "./pages/dashboard/alerts/Alerts";
import { Account } from "./pages/dashboard/account/Account";
import { Settings } from "./pages/dashboard/settings/Settings";
import { PatternForm } from "./pages/dashboard/patterns/PatternForm";
import { ToastProvider } from "./contexts/ToastContext";
import { DashboardProvider } from "./contexts/DashboardContext";
import { Plans } from "./pages/dashboard/plans/Plans";
import { Telegram } from "./pages/dashboard/telegram/Telegram";
import { Logout } from "./pages/logout/Logout";
import { NotFound } from "./pages/notfound/NotFound";
import { CheckoutConcluded } from "./pages/dashboard/plans/CheckoutConcluded";

function LoadingState() {
  return (
    <div className="w-100 h-100 flex-center">
      <span className="text-muted">Carregando...</span>
    </div>
  )
}

function LoginWrapper() {
  const { loading } = useContext(AuthContext)
  if (loading) return <LoadingState />
  return <Login />
}

function DashboardWrapper() {

  const location = useLocation()
  const { user, loading } = useContext(AuthContext)

  if (loading) return <LoadingState />
  if (!user) return <Navigate to={'/login'} replace state={{ from: location }} />

  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  )
}

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="login" element={<LoginWrapper />} />
            <Route path="logout" element={<Logout />} />
            <Route path="dashboard" element={<DashboardWrapper />}>
              <Route path="" element={<Navigate to="/dashboard/home" />} />
              <Route path="home" element={<DashboardHome />} />
              <Route path="patterns" element={<Patterns />} />
              <Route path="patterns/new" element={<PatternForm />} />
              <Route path="patterns/edit/:id" element={<PatternForm isEdit={true} />} />
              <Route path="alerts" element={<Alerts />} />
              <Route path="account" element={<Account />} />
              <Route path="plans" element={<Plans />} />
              <Route path="plans/checkout/concluded" element={<CheckoutConcluded />} />
              <Route path="telegram" element={<Telegram />} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
