import './Plans.css'
import { Container } from 'react-bootstrap'
import { useDashboard } from '../../../contexts/DashboardContext'
import { Check } from '@mui/icons-material'
import { LoaderButton } from '../../../components/LoaderButton'
import { useEffect, useState } from 'react'
import { Api } from '../../../service/Api'
import { useToast } from '../../../contexts/ToastContext'

/**
 * Plan List Component
 * 
 * @returns Plan List Component
 */
export const Plans = () => {

    const toast = useToast()
    const dashboard = useDashboard()
    const [plans, setPlans] = useState(dashboard.plans.data)

    useEffect(_ => {
        setPlans(dashboard.plans.data)
    }, [dashboard.plans.data])

    function setPlanLoading(plan, loading) {
        setPlans(state => {
            let newPlans = [...state]
            newPlans.find(el => el.id === plan.id).loading = loading
            return newPlans
        })
    }

    function handleClickAssignPlan(plan) {
        setPlanLoading(plan, true)
        Api.createUserPlan(plan.id)
            .then(result => {
                const response = result.data
                window.location.href = response.init_point
            })
            .catch(_ => toast.error('Ocorreu um erro'))
            .finally(_ => setPlanLoading(plan, false))
    }

    return (
        <div className="scrollable">
            <Container className="py-3">
                <div className='Plan-container'>
                    {plans.map(plan => (
                        <Plan
                            key={plan.id}
                            plan={plan}
                            onClickAssingPlan={handleClickAssignPlan} />
                    ))}
                </div>
            </Container>
        </div>
    )
}

/**
 * Plan Item
 * 
 * @param {*} param0 
 * @returns Plan Component
 */
const Plan = ({ plan, onClickAssingPlan }) => {

    const formatMoney = (value) => {
        return parseFloat(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        })
    }

    return (
        <div className={`Plan ${plan.type.toLowerCase()}`}>
            <div>
                <h5 className='name'>{plan.name}</h5>
                <p className='price'>{formatMoney(plan.priceMonthly)}<span className='monthly'>/mês</span></p>
                <ul className='benefits'>
                    {plan.benefits.map((benefit, idx) => (
                        <li key={plan.id.concat(idx)}>
                            <Check />
                            {benefit}
                        </li>
                    ))}
                </ul>
            </div>

            <LoaderButton
                loading={plan.loading}
                visible={plan.type !== "FREE"}
                onClick={_ => onClickAssingPlan(plan)}>
                Assinar {plan.name}
            </LoaderButton>
        </div>
    )
}