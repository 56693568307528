import './Account.css'
import { Card, Container } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import { ChevronRight, LogoutRounded, Telegram, VerifiedOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export const Account = () => {

    const { user } = useAuth()
    const navigate = useNavigate()

    return (
        <div className="scrollable">
            <Container className="py-3">
                <Card className="mb-4">
                    <h5 className='mb-5'>Minha Conta</h5>

                    <div className="profile">
                        <img className="photo" src={user.photoURL} alt={user.name} width={72} height={72} />
                        <h2 className="name">{user.name}</h2>
                        <p className="email">{user.email}</p>
                    </div>

                    <nav className="nav-account-menu">
                        <ul>
                            <li onClick={_ => navigate('/dashboard/telegram')}>
                                <span><Telegram className='icon' /> Telegram</span>
                                <ChevronRight />
                            </li>
                            <li onClick={_ => navigate('/dashboard/plans')}>
                                <span><VerifiedOutlined className='icon' /> Assinatura</span>
                                <ChevronRight className='icon' />
                            </li>
                            <li onClick={_ => navigate('/logout')}>
                                <span><LogoutRounded className='icon' /> Sair</span>
                                <ChevronRight className='icon' />
                            </li>
                        </ul>
                    </nav>

                </Card>
            </Container>
        </div>
    )
}