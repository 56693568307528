import { useCallback, useEffect, useState } from "react"
import { DashboardHeader } from "../../components/DashboardHeader"
import { Sidebar } from "../../components/Sidebar"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Breadcrumb } from "./Breadcrumb"
import { AccountCircleRounded, HomeRounded, PatternRounded, VerifiedOutlined, Telegram } from "@mui/icons-material"
import { Container } from "react-bootstrap"

const LARGE_DEVICE_SIZE = 992

const menu = [
    {
        label: 'Início',
        icon: <HomeRounded />,
        path: '/dashboard/home',
        children: []
    },
    {
        label: 'Meus Padrões',
        icon: <PatternRounded />,
        path: '/dashboard/patterns',
        children: [
            {
                label: 'Criar Padrão',
                path: '/new'
            },
            {
                label: 'Editar',
                path: '/edit'
            }
        ]
    },
    {
        label: 'Telegram',
        icon: <Telegram />,
        path: '/dashboard/telegram',
        children: []
    },
    {
        label: 'Planos',
        icon: <VerifiedOutlined />,
        path: '/dashboard/plans',
        children: []
    },
    {
        label: 'Minha Conta',
        icon: <AccountCircleRounded />,
        path: '/dashboard/account',
        children: []
    }
]

export const Dashboard = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const checkIsLargeDevice = () => (typeof window !== 'undefined' ? window.innerWidth > LARGE_DEVICE_SIZE : false)
    const [isLargeDevice, setIsLargeDevice] = useState(checkIsLargeDevice())

    const [sidebarOpen, setSidebarOpen] = useState(checkIsLargeDevice())
    const toggleSidebarOpen = () => setSidebarOpen(!sidebarOpen)

    const [menuSelected, setMenuSelected] = useState(location.pathname)

    const handleChangePath = useCallback(_ => {
        let path = location.pathname
        let menuItem = menu.find(menu => path.includes(menu.path))
        setMenuSelected(menuItem.path)
    }, [location.pathname])

    useEffect(handleChangePath, [handleChangePath])
    useEffect(() => {

        const handleResize = () => {
            let isDeviceLarge = checkIsLargeDevice()
            setIsLargeDevice(isDeviceLarge)
            setSidebarOpen(isDeviceLarge)
        };

        window.addEventListener('resize', handleResize);

        // Cleanup para evitar vazamentos de memória
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function onClickMenuItem(menu) {
        navigate(menu)
        if (!isLargeDevice) setSidebarOpen(false)
    }

    function onClickSidebarPlan() {
        navigate('/dashboard/account/plans')
        if (!isLargeDevice) setSidebarOpen(false)
    }

    return (
        <div className="d-lg-flex vh-100">

            <Sidebar
                menu={menu}
                menuSelected={menuSelected}
                open={sidebarOpen}
                onClickMenu={onClickMenuItem}
                onClickPlan={onClickSidebarPlan}
                toggle={toggleSidebarOpen} />

            <main className="d-flex flex-column flex-fill vh-100">
                <DashboardHeader toggle={toggleSidebarOpen} />

                <Container className="mt-4">
                    <Breadcrumb menu={menu} />
                </Container>

                <Outlet />
            </main>
        </div>
    )
}