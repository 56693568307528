import { Card } from "react-bootstrap"
import { useDashboard } from "../../../../contexts/DashboardContext"
import { Radar } from "../../../../components/Radar"

export const RoulettesSection = () => {

    const dashboard = useDashboard()

    const roulettes = dashboard.roulettes.data.filter(r => r.active)

    if (roulettes.length === 0) {
        return <></>
    }

    return (
        <Card>
            <h5 className="mb-5">Roletas sendo analisadas</h5>
            <ul className="m-0 p-0 list-unstyled d-flex flex-column gap-2">
                {dashboard.roulettes.data.filter(r => r.active).map(roulette => (
                    <li key={roulette.id} className="d-flex align-items-center gap-3">
                        <Radar /> {roulette.name}
                    </li>
                ))}
            </ul>
        </Card>
    )
}